import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
	<Layout>
		<SEO title="Seite nicht gefunden" />
		<span itemScope itemType="http://schema.org/Article">
				<section id="article">
					<article className="text">
						<div className="ribbon" />
						<header>
							<h2>
								Seite nicht gefunden
							</h2>
							<p>&nbsp;</p>
						</header>
						<hr />
						<section>
							<p>Was hast du hier gesucht?!</p>
						</section>
					</article>
				</section>
			</span>
	</Layout>
);

export default NotFoundPage;
